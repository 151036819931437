.app {
  background-color: var(--primary-color);
  font-family: var(--font-base);
  display: flex;
  flex-direction: row;
  @media (max-width: 480px) {
    flex-direction: column;
    width: 100%;
  }
}

.app__primarybg {
  background-color: var(--primary-color);
}

.app__container {
  position: absolute;
  left: 15%;
  width: 85%;
  min-height: 100vh;
  @media (max-width: 480px) {
    left: 0;
    top: 10%;
    min-width: 100%;
  }
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__wrapper {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 2rem;
  min-height: 100vh;
  overflow: hidden;

  @media (max-width: 480px) {
    padding: 1rem;
  }
}

.p-text {
  font-size: 0.8rem;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;
  @media screen and (min-width: 1400px) {
    font-size: 1.2rem;
  }
  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
  @media (min-width: 820px) {
    font-size: 1rem;
  }
}

.heading-text {
  @media (max-width: 480px) {
    padding-top: 2rem;
    margin-top: 1rem;
  }
}

.heading-text h1 {
  position: relative;
  z-index: 0;
  color: #334b48;
  font-weight: 600;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  @media (max-width: 480px) {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
}

.heading-text div {
  background-color: #00c6b5;
  opacity: 0.2;
  height: 20px;
  position: absolute;
  z-index: 0;
  border-radius: 10%;
}

a {
  text-decoration: none;
}
