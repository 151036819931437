.app__navbar {
  width: 15%;
  position: fixed;
  height: 100%;
  @media (max-width: 480px) {
    position: fixed;
    height: 10%;
    width: 100%;
    z-index: 10;
  }

  .item-link {
    text-decoration: none;
  }
  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    min-height: 100vh;
    transition: all 0.5s ease-out !important;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    @media (max-width: 480px) {
      flex-direction: row;
      min-height: 100%;
    }
  }
  li {
    list-style-type: none;
    cursor: pointer;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media (max-width: 480px) {
        flex-direction: row;
      }
    }

    &.active {
      transform: scale(1.2);
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -o-transform: scale(1.2);
    }
    &.off {
      transform: scale(0.7);
      -webkit-transform: scale(0.7);
      -moz-transform: scale(0.7);
      -o-transform: scale(0.7);
      transition-delay: 0.25s;
    }
    &:hover {
      opacity: 0.5;
    }
    .icon {
      color: #383d46;
      font-size: 2.5rem;
      margin-bottom: 0.5rem;
      transition: all 0.5s ease-out;
      -webkit-transition: all 0.5s ease-out;
      -moz-transition: all 0.5s ease-out;
      -o-transition: all 0.5s ease-out;
      @media (max-width: 480px) {
        margin-bottom: 0;
      }
    }
    a {
      text-decoration: none;
      text-align: center;
    }
    p {
      text-transform: uppercase;
      font-weight: 600;
      text-decoration: none;
      color: #383d46;
      letter-spacing: 0.2rem;
      @media (max-width: 480px) {
        display: none;
      }
    }
  }
}
