.app__socialmediaicon-wrapper {
  position: fixed;
  z-index: 3;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  right: 4%;
  bottom: 5%;

  @media (max-width: 480px) {
    display: none;
  }
}

.app__socialmediaicon {
  width: 60px;
  height: 60px;
  background: #d10505;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: background 0.2s;
}

.plus-vertical,
.plus-horizontal {
  background: #fff;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.plus-vertical {
  height: 50%;
  width: 5px;
}

.plus-horizontal {
  width: 50%;
  height: 5px;
}

.quadrant {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 20px rgba(240, 236, 47, 0.9);
}

.quadrant__item {
  width: 50%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d10505;
  border-radius: 6px;
  position: relative;
}

.quadrant__item__content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
}

.social-icon {
  color: #fff;
  transition: 0.3s;
}

.social-icon:hover {
  color: rgba(255, 251, 0, 0.9);
  scale: 1.4;
}

.quadrant__item__content a {
  text-decoration: none;
}

#arrow-down,
#arrow-left,
#arrow-right,
#arrow-up {
  opacity: 0;
}

#arrow-up {
  transform: translateY(10px);
}

#arrow-down {
  transform: translateY(-10px);
}

#arrow-left {
  transform: translateX(10px);
}

#arrow-right {
  transform: translateX(-10px);
}
