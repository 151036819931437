.contact-wrapper-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact__formheading-contact {
  width: 70%;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  background-color: #f2f2f2;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  padding: 0 1.3rem;
  margin: 2rem;
  @media (max-width: 1024px) {
    width: 90%;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding: 0.5rem;
    margin: 0 0.5rem 2rem 0.5rem;
  }
}

.c-icons {
  color: #383d46;
  margin-right: 0.5rem;
  @media screen and (min-width: 1400px) {
    font-size: 1.5rem;
  }
  &:hover {
    transform: scale(1.3);
    transition: all 0.4s ease-in-out;
  }
}

.contact__formheading-details {
  width: 60%;
  margin: 0.5rem 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;
  @media (max-width: 480px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

.contact__formheading-email a,
.contact__formheading-phone a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.contact-formheading-heading h1 {
  margin: 0;
  color: #46364a;
  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
}

.contact-profilepic {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #fef4f5;
  box-shadow: 0 0 15px #46364a;
  overflow: hidden;
  text-align: center;
  margin: 1rem;
  @media (max-width: 480px) {
    margin: 0.5rem;
  }
}

.contact-profilepic img {
  padding-top: 0.5rem;
  width: 70%;
  height: 70%;
}

.contact__form-container {
  width: 70%;
  border-radius: 5px;
  background-color: #f2f2f2;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 0 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    width: 90%;
  }
  @media (max-width: 480px) {
    width: 90%;
    padding: 0 1rem 1rem;
    margin: 0 0.5rem 2rem 0.5rem;
  }
}

.contact-form-status {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
  color: #00c6b5;
  margin-bottom: 1rem;
  &.error {
    color: #d10505;
  }
  @media (max-width: 480px) {
    font-size: 1.2rem;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 0;
  }
}

input[type="text"],
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type="submit"] {
  background-color: #383d46;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
}

input[type="submit"]:hover {
  opacity: 0.5;
}

label {
  color: #000;
}

textarea,
input {
  font-family: Arial, sans-serif;
  font-size: 1rem;
}
