.app__header {
  flex: 1;
  width: 100%;
  overflow: hidden;
  @media (max-width: 480px) {
    position: relative;
    width: 100%;
  }
}

.app__header-img {
  min-height: 100vh;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  @media (max-width: 480px) {
    min-height: 90vh;
    justify-content: flex-end;
    object-fit: contain;
  }
  .bg_profile {
    position: absolute;
    height: 70%;
    object-fit: contain;
    z-index: 1;
    @media (max-width: 480px) {
      right: 0;
    }
  }

  .bg_circle {
    position: absolute;
    object-fit: contain;
    height: 80%;
    right: -10%;
    bottom: -10%;
    z-index: 0;
    @media (max-width: 1024px) {
      right: -40%;
      bottom: -10%;
    }
    @media (max-width: 480px) {
      height: 75%;
      right: -35%;
      bottom: -10%;
    }
  }
}

.home__header-circles {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  height: 100%;
  right: 55%;
  z-index: 2;
  @media (max-width: 1024px) {
    right: 75%;
  }
  @media (max-width: 819px) {
    display: none;
  }
}

.home__header-circle {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  &:hover {
    scale: 1.1;
    animation: spin 1s linear infinite;
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    transition: all 0.5s ease-out;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
  }
}

.home__header-circle img {
  width: 60%;
  height: 60%;
}

#home__header-circle-redux {
  position: relative;
  top: 10%;
  right: -150%;
  width: 100px;
  height: 100px;
  @media (max-width: 1024px) {
    top: 15%;
    right: -100%;
  }
}

#home__header-circle-react {
  width: 140px;
  height: 140px;
}

#home__header-circle-node {
  width: 60px;
  height: 60px;
}

.home__welcometext {
  position: absolute;
  top: 0;
  left: 0;
}
.home__messagecloud {
  margin: 2rem 0 0 2rem;
  padding: 3rem;
  border-radius: 1rem;
  display: inline-flex;
  align-items: flex-end;
  flex-direction: column;
  position: relative;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  color: #313bac;
  @media (max-width: 480px) {
    margin: 1rem 0 0 1rem;
    padding: 1rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
}

.home__messagecloud-tick {
  content: "";
  position: absolute;
  left: 50%;
  border: 3em solid transparent;
  border-top-color: #fff;
  border-bottom: 0;
  border-right: 0;
  margin-bottom: -3em;
  @media (max-width: 480px) {
    left: 75%;
  }
}

#home__messagecloud-heading {
  margin-bottom: 0;
  font-size: 3em;
  @media (max-width: 480px) {
    font-size: 2em;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
