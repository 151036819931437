.projects__container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
}

#project-card {
  display: flex;
  overflow: hidden;
  margin: 1rem;
  width: 40%;
  max-width: 580px;
  border-radius: 0.5rem;
  box-shadow: 0 0 15px #46364a;
  @media (max-width: 1024px) {
    width: 90%;
  }
}

.project-image {
  transition: all 0.3s ease-out;
  position: relative;
  overflow: hidden;
}

#project-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-info {
  background: rgba(27, 26, 26, 0.9);
  transition: all 0.3s ease-out;
  transform: translateX(-100%);
  position: absolute;
  color: #fff;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  @media (max-width: 480px) {
    background: rgba(27, 26, 26, 0.6);
    transform: translateX(0);
  }
}

.project-info-head {
  padding: 1rem;
  max-height: 50%;
  @media (max-width: 480px) {
    max-height: 70%;
  }
}

.project-info-bottom {
  padding: 1rem;
}

.project-info-bottom p {
  margin: 0.5rem 0;
  @media (max-width: 480px) {
    display: none;
  }
}

.project-info h2 {
  margin-bottom: 0.5rem;
  color: #e4e4e4;

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
}

.project-info p {
  color: #fff;
}

#stack-title {
  margin: 0;
  font-size: 0.8rem;
  font-weight: 500;
}

.project-image:hover .project-info {
  transform: translateX(0);
}
.project-image:hover img {
  transition: all 0.3s ease-out;
  transform: scale(1.2, 1.2);
}

.imghover-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.imghover-actions p {
  margin-right: 0.5rem;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  @media (max-width: 480px) {
    display: flex;
  }
}

.imghover-icons {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  margin: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  @media (max-width: 480px) {
    width: 30px;
    height: 30px;
  }
}

.imghover-actions a {
  text-decoration: none;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
