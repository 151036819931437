.aboutme__skills-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: 480px) {
    align-items: flex-start;
  }
}

.aboutme__skills-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15%;
  margin: 1rem;
  @media (max-width: 480px) {
    width: 21%;
    margin: 1.2rem;
  }

  div {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #fef4f5;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 15px #46364a;
    margin-bottom: 0.8rem;

    @media (max-width: 480px) {
      width: 60px;
      height: 60px;
    }
  }

  img {
    width: 50%;
    height: 50%;
  }

  p {
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.1rem;
    @media (max-width: 480px) {
      font-size: 0.6rem;
    }
  }
}

.aboutme__text-container {
  margin-bottom: 2rem;
  @media (max-width: 480px) {
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 1.2rem;
    line-height: 2;
    text-align: justify;
    text-justify: inter-word;
    @media (max-width: 480px) {
      font-size: 1rem;
      line-height: 2;
    }
  }
}
